<template>
  <div>
    <slot name="activator" v-bind:onShowModal="onShowModal">
      <b-button @click="onShowModal" variant="success" size="sm">Crear</b-button>
    </slot>

    <b-modal
      v-model="modal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span :class="isEditingResource ? 'text-danger' : ''">{{ showFormTitle }}</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <b-card :class="{ isLoading }">
          <FormulateForm
            ref="inpcForm"
            name="inpcForm"
            v-model="formValues"
            :errors="response.errors"
            @submit="submit"
          >
            <b-row>
              <!-- Periodo -->
              <b-col cols="12" md="12">
                <FormulateInput
                  name="periodo_id"
                  validation="bail|required"
                  validation-name="Periodo"
                  error-behavior="live"
                >
                  <template #element>
                    <x-form-group title="Periodo" required>
                      <x-select
                        v-model="formValues.periodo_id"
                        dispatchPath="periodoModule/getResource"
                        getterListPath="periodoModule/getResourceList"
                        :formatListWith="['id', 'descriptor']"
                        no-validate
                        defaultText="-- Selecciona un periodo --">
                      </x-select>
                    </x-form-group>
                  </template>
                </FormulateInput>
              </b-col>
            </b-row>

            <b-row>
              <!-- Valor -->
              <b-col cols="12" md="12">
                <FormulateInput
                  name="indice"
                  label="Valor"
                  required
                  type="text"
                  validation="required"
                  validation-name="Valor"
                />
              </b-col>
            </b-row>
          </FormulateForm>
        </b-card>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.inpcForm ? $refs.inpcForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="isEditingResource"
            :isCreating="!isEditingResource"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

const formValues = () => ({
  descriptor: '',
  indice: ''
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

const STORE_MODULE = 'inpcModule'

export default {
  name: 'INPCForm',

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      modal: this.value,

      isLoading: false,

      formValues: formValues(),

      response: response()
    }
  },

  computed: {
    //
    // INPC module
    ...mapState(STORE_MODULE, ['editingResource']),
    ...mapGetters(STORE_MODULE, ['isEditingResource']),

    showFormTitle () {
      return this.isEditingResource
        ? `Editando inpc: '${this.editingResource.periodo.descriptor}'`
        : 'Crear nuevo inpc'
    }
  },

  watch: {
    isEditingResource: {
      handler (value) {
        if (value) {
          const {
            id,
            indice,
            periodo
          } = this.editingResource
          this.formValues.id = id
          this.formValues.periodo_id = periodo.id
          this.formValues.indice = indice
          return
        }

        this.resetForm()
      }
    },

    value: {
      immediate: true,
      handler (value) {
        this.modal = value
      }
    },

    modal: {
      immediate: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    /**
     * Envía el formulario.
     *
     * Envía el formulario para su creación o su actualización.
     * Dependiendo si la variable "isEditingUser" es verdadera actualizará
     * si no es así entonces mandará crear.
     */
    async submit () {
      console.log('submit')
      this.response = response()
      this.prepareInput()

      this.isLoading = true
      const { error, message, data } = this.isEditingResource
        ? await this.updateResource(this.formValues)
        : await this.createResource(this.formValues)
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },

    async createResource (form) {
      return await this.$store.dispatch(`${STORE_MODULE}/createResource`, form)
    },

    async updateResource (form) {
      return await this.$store.dispatch(`${STORE_MODULE}/updateResource`, {
        id: form.id,
        payload: form
      })
    },

    /**
     * Prepara los datos antes de enviarlos al backend.
     *
     * Prepara los datos necesarios como la fecha o los roles y permisos que
     * se deben procesar en el backend.
     */
    prepareInput () {
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit(`${STORE_MODULE}/setResourceToEdit`, null)
      this.$formulate.reset('inpcForm')
      this.formValues = formValues()
      this.response = response()

      this.modal = false
    },

    onShowModal () {
      this.modal = true
      this.$emit('input', this.modal)
    }
  }
}
</script>

<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
