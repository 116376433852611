<template>
  <base-view title="INPC" icon="percent">
    <div>
      <check-authorization :requiresAuthorizations="['indice inpc']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            INPC

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
              <check-authorization
                :requiresAuthorizations="['crear inpc']"
                :overrideIf="$store.getters['inpcModule/isEditingResource']"
              >
                <i-n-p-c-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                />
              </check-authorization>
              <!-- Search -->
              <!-- <i-n-p-c-search></i-n-p-c-search> -->
              <!-- Options -->
              <!-- <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getUsers(null)">
                    Recargar
                  </a>
                </div>
              </div> -->
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <i-n-p-c-list @on-got-item="showForm = true"></i-n-p-c-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import INPCForm from '@/components/Administracion/INPC/INPCForm'
import INPCList from '@/components/Administracion/INPC/INPCList'
// import INPCSearch from '@/components/Administracion/INPC/INPCSearch'
import { mapActions, mapGetters } from 'vuex'

const STORE_MODULE = 'inpcModule'

export default {
  name: 'INPCMainView',

  components: {
    INPCForm,
    INPCList
  },

  data () {
    return {
      hideButton: false,
      showForm: false
    }
  },

  computed: mapGetters(STORE_MODULE, ['isEditingResource']),

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>

</style>
